<template>
  <div>
    <vue-headful
      :title="'Search Results for \'' + searchTerm + '\' - Where does it Go?'"
    />
    <h2>Search Results</h2>
    <p v-if="filteredAliases.length > 0 && searchTerm.length == 0">
      Please enter a search term
    </p>
    <p v-else-if="filteredAliases.length > 0">
      Showing results for <strong>"{{ searchTerm }}"</strong>
    </p>
    <p v-else>
      Sorry, there are no results for <strong>"{{ searchTerm }}"</strong>
    </p>
    <ul v-if="filteredAliases.length > 0 && searchTerm.length > 0">
      <items-list
        v-for="item in filteredAliases"
        :item="item"
        :key="'alias - ' + item.name"
      ></items-list>
    </ul>
  </div>
</template>

<script>
import ItemsList from "@/components/ItemsListBasic";

export default {
  name: "SearchResults",
  props: {
    aliases: Array,
    searchTerm: String
  },
  components: {
    ItemsList
  },
  computed: {
    filteredAliases() {
      return this.aliases.filter(item => {
        return (
          item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        );
      });
    }
  }
};
</script>
