import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vueHeadful from "vue-headful";
import "./polyfills.js";
import "./assets/tailwind.css";

// CSS - TODO: Distribute into the Single File Components
// import scss from './wdig.scss'; // eslint-disable-line

Vue.component("vue-headful", vueHeadful);
String.prototype.toProperCase = function() {
  return this.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
console.log("Logging from main.js...");

window.appWDIG = new Vue({
  router,
  components: { App },
  data() {
    return {
      items: [],
      aliases: [],
      rates: [],
      nav: [],
    };
  },
  render: h => h(App)
}).$mount("#app");
