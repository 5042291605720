<template>
  <div v-if="item[pkey]">
    <h3 :class="pkey">{{ val }}</h3>
    <div v-if="rates.length > 0" v-html="addRates(item[pkey], rates)"></div>
    <div v-else v-html="item[pkey]"></div>
  </div>
</template>

<script>
export default {
  name: "item-section",
  props: {
    item: Object,
    pkey: String,
    val: String,
    rates: Array
  },
  methods: {
    addRates(str, rates) {
      // From Nik
      // Regex statement to look for any string that has 1-3 digits between "{{proj" && ".rateText}}"
      var regex = /{{proj\[(\d{1,3})\].rate_text}}/;

      // Replace the first match with rate info
      var newText = str.replace(regex, function(rate) {
        var index = rate.match(/\d+/g).map(Number);
        var rateText = rates[index].rate_text;
        return rateText;
      });

      // Keep looping through this function until all matches are replaced.
      var anotherOne = newText.search(regex);
      if (anotherOne > 0) {
        newText = this.addRates(newText, rates);
      }
      return newText;
    }
  }
};
</script>
