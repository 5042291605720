<template>
  <div>
    <a href="" class="back" v-on:click.prevent="$router.go(-1)">Back</a>
    <item-view
      v-for="item in selectedItem"
      :item="item"
      :key="item.id"
      :rates="rates"
    />
  </div>
</template>

<script>
import ItemView from "@/components/ItemView";

export default {
  name: "Item",
  props: {
    items: Array,
    aliases: Array,
    rates: Array,
    id: String,
    name: String
  },
  components: {
    ItemView
  },
  computed: {
    selectedItem() {
      if (this.id) {
        return this.findById(this.id);
      } else if (this.name) {
        return this.findByName(this.name);
      }

      return "";
    }
  },
  methods: {
    findById(id) {
      return this.items.filter(item => {
        return item.id == id;
      });
    },
    findByName(name) {
      let nameIDpair = this.aliases.filter(item => {
        return this.hyphenate(item.name) == name;
      })[0];

      if (typeof nameIDpair !== "undefined") {
        return this.findById(nameIDpair.id);
      }
    },
    hyphenate(item) {
      // Strip quotes, commas, periods, hashes
      item = item.replace(/"|,|\.|#/g, "");
      // Replace spaces with hyphen
      return item.replace(/\s-\s|\s+/g, "-").toLowerCase();
    }
  }
};
</script>
