/*
    NOTE:
    Most of these are for vendor related scripts
    that don't get transpiled by Babel.

    See babel.config.js for polyfills loaded from core-js
    _____________________________________________________________

    TOC:
    - closest
    _____________________________________________________________
*/

// closest
// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
// 'matches' and 'closest' polyfills by @jonathantneal
(function(ElementProto) {
  if (typeof ElementProto.matches !== "function") {
    ElementProto.matches =
      ElementProto.msMatchesSelector ||
      ElementProto.mozMatchesSelector ||
      ElementProto.webkitMatchesSelector ||
      function matches(selector) {
        var element = this;
        var elements = (
          element.document || element.ownerDocument
        ).querySelectorAll(selector);
        var index = 0;

        while (elements[index] && elements[index] !== element) {
          ++index;
        }

        return Boolean(elements[index]);
      };
  }

  if (typeof ElementProto.closest !== "function") {
    ElementProto.closest = function closest(selector) {
      var element = this;

      while (element && element.nodeType === 1) {
        if (element.matches(selector)) {
          return element;
        }

        element = element.parentNode;
      }

      return null;
    };
  }
})(window.Element.prototype);
