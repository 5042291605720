<template>
  <div>
    <vue-headful title="A to Z List - Where does it Go?" />
    <p>
      Many items have different names and we can't list them all. If you don't
      find your individual item on the list below, try using categories or
      search.
    </p>
    <ul class="aToZList">
      <items-list
        v-for="(item, index) in items"
        :previousLetter="previousLetter(items[index - 1])"
        :item="item"
        :key="'content - ' + item.id"
      ></items-list>
    </ul>
  </div>
</template>

<script>
import ItemsList from "@/components/ItemsList";

export default {
  name: "AtoZList",
  props: { items: Array },
  components: {
    ItemsList
  },
  methods: {
    previousLetter: function(item) {
      var letter = "";
      if (typeof item !== "undefined")
        letter = item.name.charAt(0).toLowerCase();
      return letter;
    }
  }
};
</script>
