import Vue from "vue";
import Router from "vue-router";
import SearchResults from "./views/pageSearchResults.vue";
import Categories from "./views/pageCategories.vue";
import AtoZList from "./views/pageAtoZList.vue";
import Item from "./views/pageItem.vue";

Vue.use(Router);

export default new Router({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      redirect: "categories"
    },
    {
      path: "/search-results",
      name: "SearchResults",
      component: SearchResults
    },
    {
      path: "/categories",
      name: "Categories",
      component: Categories,
      children: [
        {
          path: "/categories/:cat",
          name: "Cat",
          children: [
            {
              path: "/categories/:cat/:sCat",
              name: "SubCat",
              children: [
                {
                  path: "/categories/:cat/:sCat/:ssCat",
                  name: "SubSubCat",
                  children: [
                    {
                      path: "/categories/:cat/:sCat/:ssCat/:sssCat",
                      name: "SubSubSubCat"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: "/a-z",
      name: "AtoZList",
      component: AtoZList
    },
    {
      path: "/item/id/:id",
      name: "ItemByID",
      component: Item,
      props: true
    },
    {
      path: "/item/:name",
      name: "ItemByName",
      component: Item,
      props: true
    }
  ]
});
