<template>
  <li>
    <h3 v-if="isNewLetter == true" :id="'jump_' + letter">{{ letter }}</h3>
    <item-link :item="item" />
  </li>
</template>

<script>
import ItemLink from "./ItemLink";

export default {
  name: "items-list",
  data() {
    return {
      letter: ""
    };
  },
  props: {
    item: Object,
    previousLetter: String
  },
  components: {
    ItemLink
  },
  computed: {
    isNewLetter: function() {
      // eslint-disable-next-line
      this.letter = this.item.name.charAt(0).toLowerCase();

      return this.previousLetter == this.letter ? false : true;
    }
  }
};
</script>
