<template>
  <li>
    <span v-if="item.children && item.children.length">
      <vue-headful :title="pageTitle" />
      <router-link
        :to="{ path: pathToCategory }"
        event=""
        @click.native.prevent="toggleChildren()"
      >
        {{ item.name }}
      </router-link>
      <ul>
        <nested-nav
          v-for="child in item.children"
          :item="child"
          :key="item.name + child.name"
          :parentCategories="pathToCategory"
        />
      </ul>
    </span>
    <span v-else>
      <item-link :item="item" />
    </span>
  </li>
</template>

<script>
import ItemLink from "./ItemLink";

export default {
  name: "nested-nav",
  props: {
    item: Object,
    parentCategories: String
  },
  computed: {
    pathToCategory() {
      let path = "/categories",
        category = "/" + this.hyphenate(this.item.name);

      return this.parentCategories
        ? this.parentCategories + category
        : path + category;
    },
    pageTitle() {
      const suffix = " - Where does it Go?";
      let pageTitle = this.isActiveRoute
        ? this.item.name
        : "Browse by Categories";
      pageTitle += suffix;

      return pageTitle;
    },
    isActiveRoute() {
      return this.$route.path == this.pathToCategory ? true : false;
    }
  },
  components: {
    ItemLink
  },
  methods: {
    hyphenate(item) {
      // Strip quotes, commas, periods
      item = item.replace(/"|,|\./g, "");
      // Replace spaces with hyphen
      return item.replace(/\s-\s|\s+/g, "-").toLowerCase();
    },
    toggleChildren() {
      let link = this.$el.querySelector("a");

      if (link.classList.contains("router-link-active")) {
        let currentPath = this.pathToCategory;
        let parentPath = currentPath.substr(0, currentPath.lastIndexOf("/"));
        this.$router.push(parentPath);
      } else {
        this.$router.push(this.pathToCategory);
      }
    }
  },
  mounted() {
    //console.log('yo');
  },
  watch: {
    // $route (to, from){
    // 		// console.log(this.$route.path);
    // 		// console.log();
    // 	// if (this.$route.path == this.pathToCategory) {
    // 	// 	console.log(this.item.name)
    // 	// }
    // 	// console.log(to);
    // 	// console.log(from);
    // }
  }
};
</script>
