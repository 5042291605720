<template>
  <router-link
    :to="{ path: '/item/' + this.hyphenate(item.name) }"
    @click.native="scrollToTop"
    >{{ item.name.toLowerCase() }}</router-link
  >
</template>

<script>
export default {
  name: "items-link",
  props: ["item"],
  methods: {
    hyphenate(item) {
      // Strip quotes, commas, periods, hashes
      item = item.replace(/"|,|\.|#/g, "");
      // Replace spaces with hyphen
      return item.replace(/\s-\s|\s+/g, "-").toLowerCase();
    },
    scrollToTop() {
      window.scrollTo(0, 200);
    }
  }
};
</script>
