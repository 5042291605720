<template>
  <li>
    <item-link :item="item" />
  </li>
</template>

<script>
import ItemLink from "./ItemLink";

export default {
  name: "items-list",
  props: { item: Object },
  components: {
    ItemLink
  }
};
</script>
