<template>
  <div id="app">
    <!-- <loading
      v-if="!items || items.length == 0 || renamedNavItems.length == 0"
    ></loading> -->
    <div class="container p-2">
      <form class="flex">
        <VueSimpleSuggest
          v-model="searchTerm"
          :list="aliases"
          :filter-by-query="true"
          :prevent-submit="false"
          value-attribute="name"
          display-attribute="name"
          placeholder="Search"
          class="flex-1 text-base"
        >
          <div
            slot="suggestion-item"
            slot-scope="{ suggestion }"
            class="text-base"
          >
            {{ suggestion.name }}
          </div>
        </VueSimpleSuggest>
        <button
          @click.stop.prevent="$router.push('/search-results')"
          class="bg-blue-200 py-2 px-8 mx-5 rounded-lg border-black border-2"
        >
          Go
        </button>
      </form>
    </div>
    <div class="flex">
      <router-link
        to="/categories"
        class="flex-none mx-2 p-2 border-black border-2 rounded-lg"
        >Categories</router-link
      >
      <router-link
        to="/a-z"
        class="flex-none mx-2 p-2 border-black border-2 rounded-lg"
        >A to Z List</router-link
      >
    </div>
    <router-view v-bind="routeProps" />
  </div>
</template>

<script>
const VueSimpleSuggest = require("vue-simple-suggest/dist/cjs");
import "vue-simple-suggest/dist/styles.css";
import axios from "axios";

// import Loading from '@/components/Loading';

// Do the top level data-fetching
export default {
  name: "App",
  props: {
    foo: {
      type: Number
    }
  },
  data() {
    return {
      searchTerm: "",
      items: [],
      aliases: [],
      rates: [],
      nav: [],
      urls: {
        items: "https://data.seattle.gov/resource/xtcp-3rjs.json",
        // items: 		'https://data.seattle.gov/resource/9nqp-fph6.json', /* DEBUG */
        rates: "https://data.seattle.gov/resource/j6qn-qavz.json",
        nav: "/assets/prebuilt/js/departments/spu/wdig/nav.json",
        itemsBKUP: "/assets/prebuilt/js/departments/spu/wdig/items.json",
        ratesBKUP: "/assets/prebuilt/js/departments/spu/wdig/rates.json"
      }
    };
  },
  // props: {
  //   nav: Array,
  //   items: Array,
  //   aliases: Array,
  //   rates: Array,
  // },
  computed: {
    routeProps: function() {
      console.log("route", this.$route);
      const route = this.$route.name;
      let props = {};

      if (route.indexOf("Cat") > -1) {
        props.nav = this.nav;
      }
      if (route == "AtoZList") {
        props.items = this.items;
      }
      if (route == "SearchResults") {
        props.aliases = this.aliases;
        props.searchTerm = this.searchTerm;
      }
      if (route == "ItemByID" || route == "ItemByName") {
        props.items = this.items;
        props.aliases = this.aliases;
        props.rates = this.rates;
      }

      return props;
    },
    renamedNavItems: function() {
      let nav = this.nav;
      let items = this.items;

      if (items.length != 0 && nav.length != 0) {
        // console.log('rename nav')

        let recurseNav = function(nav) {
          nav.forEach((item, index) => {
            if (item.id) {
              let contentMatch = items.find(x => x.id == item.id);

              if (contentMatch) {
                item.name = contentMatch.name;
              } else {
                nav.splice(index, 1);
              }
            }

            if (item.children) recurseNav(item.children);
          });
        };

        recurseNav(nav);
      }

      return nav;
    }
  },
  components: {
    VueSimpleSuggest
  },
  created: function() {
    this.getNav();
    this.getItems();
    this.getRates();
  },
  mounted: function() {
    this.sortByProp(this.items, "name");
  },
  methods: {
    getNav: function() {
      let winURL = window.location.href.toLowerCase(),
        navURL = "",
        locations = [
          ".gov",
          "dsspreview",
          "seattle_dss",
          "qaseattle_dss",
          "localhost:8080",
          "localhost:8081"
        ],
        navXML = "";

      function env(str) {
        return winURL.includes(str);
      }

      if (env("//www") || env("cms8.")) navXML = "x112756.xml";
      if (env("ditwinw520")) navXML = "x110553.xml";
      if (env("nine9")) navXML = "x111769.xml";

      function checkNavURL(str) {
        if (winURL.includes(str)) navURL = winURL.split(str)[0] + str + "/";
      }

      locations.forEach(item => checkNavURL(item));

      axios.get(navURL + navXML).then(res => {
        let parser = new DOMParser(),
          xml = parser.parseFromString(res.data, "text/xml"),
          navElement = xml.getElementsByTagName("Field1")[0].childNodes[0]
            .nodeValue,
          navJSON = JSON.parse(navElement);

        console.log("Nav Acquired");
        console.log(navJSON);
        this.nav = navJSON;
      });

      // let self = this;
      // axios.get(this.urls.nav)
      // .then(function(res) {
      // 	self.nav = res.data;
      // });
    },
    getItems: function() {
      let self = this;
      axios
        .get(self.urls.items)
        .then(function(res) {
          self.setItems(res.data);
        })
        .catch(function() {
          axios.get(self.urls.itemsBKUP).then(function(res) {
            self.setItems(res.data);
            this.sortByProp(this.aliases, "name");
          });
        });
    },
    setItems: function(data) {
      this.items = data;
      this.sortByProp(this.items, "name");
      this.getAliases(this.items);
      this.sortByProp(this.aliases, "name");
    },
    getAliases: function(items) {
      let appAliases = this.aliases;

      let separators = ['",', "”,", "“,"];
      let splitRegex = new RegExp(separators.join("|"), "g");
      let leftoverSeparators = ['"', "”", "“"];
      let leftoverRegex = new RegExp(leftoverSeparators.join("|"), "g");

      items.forEach(function(i) {
        let name = i.name,
          id = i.id,
          aliases = i.aliases;

        if (name) appAliases.push({ name: name.toLowerCase(), id: id });

        if (aliases) {
          aliases.split(splitRegex).forEach(function(alias) {
            // aliases.split('",').forEach(function(alias) {
            // alias = alias.replace(/["]+/g, '').toLowerCase();
            alias = alias.replace(leftoverRegex, "").toLowerCase();

            appAliases.push({ name: alias, id: id });
            // if (id == 300 || id == 229) console.log(alias)
          });
        }
      });
    },
    getRates: function() {
      let self = this;
      axios
        .get(self.urls.rates)
        .then(function(res) {
          self.rates = res.data;
        })
        .catch(function() {
          axios.get(self.urls.ratesBKUP).then(function(res) {
            self.rates = res.data;
          });
        });
    },
    sortByProp: function(items, x = "name") {
      items.sort(function(a, b) {
        var textA = a[x].toLowerCase();
        var textB = b[x].toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
  }
};
</script>

<style scoped>
/* [v-cloak] {
  display: none;
} */
#app {
  width: 100%;
  height: 200px;
}
</style>
