<template>
  <div>
    <vue-headful title="Browse by Categories - Where does it Go?" />
    <ul class="nestedNav">
      <nested-nav v-for="item in nav" :item="item" :key="item.name" />
    </ul>
  </div>
</template>

<script>
import NestedNav from "@/components/NestedNav";

export default {
  name: "Categories",
  props: { nav: Array },
  components: {
    NestedNav
  }
};
</script>
