<template>
  <article class="itemView">
    <vue-headful :title="name.toProperCase() + ' - Where does it Go?'" />
    <header>
      <h2>{{ name }}</h2>
    </header>
    <item-section
      v-for="(val, key) in sections"
      :key="key"
      :pkey="key"
      :val="val"
      :item="item"
      :rates="rates"
    >
    </item-section>
    <footer>
      <strong>Decrease waste:</strong> Choose reusable items or donate to thrift
      stores.
    </footer>
  </article>
</template>

<script>
import ItemSection from "./ItemSection";

export default {
  name: "item-view",
  data() {
    return {
      sections: {
        msg_general: "",
        msg_garbage: "In Garbage",
        msg_recycle: "In recycling",
        msg_food: "In food and yard waste",
        msg_curb: "Curb-side pickup",
        msg_binSide: "Next to bin",
        msg_dropoff: "Drop-off locations",
        msg_caution: "Caution",
        msg_hazard: "Hazardous",
        msg_transfer: "Transfer station",
        msg_donate: "Donate and reuse",
        msg_reuse: "Reuse",
        msg_didYouKnow: "Did you know?"
      }
    };
  },
  props: {
    item: Object,
    rates: Array
  },
  components: {
    ItemSection
  },
  computed: {
    name() {
      return this.item.name.toLowerCase();
    }
  }
};
</script>
